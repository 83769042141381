<template>
  <div class="p-2">
    <h1
      v-if="data.employee"
      class="h3 card-title"
    >
      {{ data.employee.name }}
    </h1>
    <h2 class="h6 card-subtitle">
      {{ moment(data.date).format('D MMMM YYYY') }}
    </h2>

    <div>
      <div
        ref="form"
        class="repeater-form"
      >
        <b-row
          v-for="(item, index) in data.timeslots"
          :id="item.id"
          :key="item.id"
          ref="row"
        >
          <b-col md="12">
            <div class="d-flex border rounded flex-column mb-1 position-relative bg-light">
              <b-link
                class="position-absolute position-right-0 zindex-1 sidebar-repeater-delete"
                @click="removeItem(index)"
              >
                <feather-icon
                  icon="XIcon"
                  size="16"
                />
              </b-link>
              <div class="row flex-grow-1 px-2 py-1">
                <b-col>
                  <validation-provider
                    #default="validationContext"
                    name="Start"
                    rules="required"
                  >
                    <b-form-group
                      label="Start"
                      label-for="start"
                    >
                      <flat-pickr
                        v-model="item.startTime"
                        class="form-control"
                        :config="{ enableTime: true, noCalendar: true, time_24hr: true, altInput: true, altFormat: 'H:i', dateFormat: 'Z', utc: true}"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col>
                  <validation-provider
                    #default="validationContext"
                    name="End"
                    rules="required"
                  >
                    <b-form-group
                      label="End"
                      label-for="end"
                    >
                      <flat-pickr
                        v-model="item.endTime"
                        class="form-control"
                        :config="{ enableTime: true, noCalendar: true, time_24hr: true, altInput: true, altFormat: 'H:i', dateFormat: 'Z', utc: true}"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-link
        variant="primary"
        @click="repeateAgain"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-25"
        />
        <span>{{ $t('settings.company.roster.add_timeslot') }}</span>
      </b-link>
    </div>

    <b-row class="pt-2">
      <b-col>
        <validation-provider
          #default="validationContext"
          name="Repeat"
          rules="required"
        >
          <b-form-group
            label="Repeat"
            label-for="repeat"
          >
            <v-select
              v-model="data.recurring"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="['Geen', 'Wekelijks', 'Tweewekelijks', 'Maandeljiks']"
              class=""
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col v-if="data.recurring">
        <validation-provider
          #default="validationContext"
          name="End repeat"
          rules="required"
        >
          <b-form-group
            label="End repeat"
            label-for="end-repeat"
          >
            <flat-pickr
              v-model="data.recurringEndDate"
              class="form-control form-clear"
              :config="{ enableTime: false, altInput: true, altFormat: 'j F Y', dateFormat: 'Y-m-d'}"
              placeholder="No end date"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BFormGroup, BFormInvalidFeedback, BRow, BCol, BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import flatPickr from 'vue-flatpickr-component'
import formValidation from '@core/comp-functions/forms/form-validation'
import { heightTransition } from '@core/mixins/ui/transition'
import moment from 'moment'

export default {
  components: {
    BFormGroup,
    BFormInvalidFeedback,
    BRow,
    BCol,
    BLink,

    ValidationProvider,
    flatPickr,
    vSelect,
  },
  mixins: [heightTransition],
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      nextId: 2,
      moment,
    }
  },
  setup() {
    const {
      getValidationState,
    } = formValidation()

    return {
      getValidationState,
    }
  },
  methods: {
    removeItem(index) {
      this.data.timeslots.splice(index, 1)
    },
    repeateAgain() {
      this.data.timeslots.push({
        id: this.nextId += this.nextId,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
